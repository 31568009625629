import React from 'react';
import './_styles.css';
import {Button} from "@material-ui/core";
import ItemStartMobile from "./item";

const StartBlock = () => {
    return (
        <div className="start-block">
            <div className="container">
                <div className="hidden-desktop-1270" style={{flexDirection: 'column', alignItems: 'center'}}>
                    <div className="start-block_mobile">
                        <ItemStartMobile number={1} text={'Зарегистрируйте Личный кабинет и пополните кошелек Equite удобным для вас способом! TRC20 USDT или Coin Payments'}/>
                        <ItemStartMobile number={2} text={'В рейтинге ПАММ-счетов выберите подходящий вариант для инвестирования. Обратите внимание на показатели доходности.'}/>
                        <ItemStartMobile number={3} text={'Ознакомьтесь с условиями оферты для участия в выбранном ПАММ-счете. Условия для разных счетов могут отличаться'}/>
                        <ItemStartMobile number={4} text={'Инвестируйте средства в выбранный ПАММ-счет или в несколько, осуществив пополнение нужной суммой из кошелька Equite, нажав кнопку "Подключиться".'}/>
                        <ItemStartMobile number={5} text={'Контролируйте доходность в личном кабинете. Ваше подключение к ПАММ-счету произойдет по истечению 24 часов. Это нужно для подготовки трейдера к новым торговым объемам.'}/>
                    </div>
                    <Button variant="contained" color="primary" style={{marginTop: 60}}>СТАТЬ ИНВЕСТОРОМ</Button>
                </div>

                <div className="start-block_circle hidden-mobile-1270">
                    <p className="start-text-1">Зарегистрируйте Личный кабинет и пополните кошелек Equite удобным для вас способом! TRC20 USDT или Coin Payments</p>
                    <p className="start-text-2">В рейтинге ПАММ-счетов выберите подходящий вариант для инвестирования. Обратите внимание на показатели доходности.</p>
                    <p className="start-text-3">Ознакомьтесь с условиями оферты для участия в выбранном ПАММ-счете. Условия для разных счетов могут отличаться.</p>
                    <p className="start-text-4">Инвестируйте средства в выбранный ПАММ-счет или в несколько, осуществив пополнение нужной суммой из кошелька Equite, нажав кнопку "Подключиться".</p>
                    <p className="start-text-5">Контролируйте доходность в личном кабинете. Ваше подключение к ПАММ-счету произойдет по истечению 24 часов. Это нужно для подготовки трейдера к новым торговым объемам.</p>
                    <div className="start-block_circle-content">
                        <h3>Как начать инвестировать?</h3>
                        <Button variant="contained" color="primary">СТАТЬ ИНВЕСТОРОМ</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StartBlock;