import React from 'react';
import Icon from './assets/bg.png';
import Icon2 from './assets/img-01.png';
import Icon3 from './assets/el1.png';
import Icon4 from './assets/el2.png';
import './_styles.css';
import {Button} from "@material-ui/core";
import StarIcon from "./assets/alert.png";

const ServiceBlock = () => {
    return (
        <div className="service-block">
            <img src={Icon} alt="" className="bg-left"/>
            <img src={Icon2} alt="" className="bg-right"/>
            <img src={Icon3} alt="" className="bg-el1"/>
            <img src={Icon4} alt="" className="bg-el2"/>

            <div className="container">
                <div className="service-block_content">
                    <h2>ПАММ-cервис от Equite</h2>
                    <h4>Соединяет инвесторов и трейдеров, помогая им сотрудничать взаимовыгодно!</h4>
                    <p>Для инвестора вложения в ПАММ-счет - это способ максимально комфортно и прибыльно осуществлять прирост своего капитала.</p>
                    <p>Для трейдера - это возможность наращивать обороты сделок и зарабатывать больше с меньшим риском.</p>
                    <p>Каждый день мы работаем для вас, развивая и дорабатывая наш сервис, поэтому его популярность растет с каждым днем!</p>
                    <Button variant="contained" color="primary">Инвестировать</Button>
                </div>
                <div className="secure-content_alert">
                    <img src={StarIcon} alt=""/>
                    <div className="secure-line">
                        <div className="secure-line_child" />
                    </div>
                    <p>Прибыль, полученная на ПАММ-счете в прошлые периоды, не является гарантией получения доходов в будущем! Equite, предоставляя сервис ПАММ-счет для инвесторов и управляющих, не выступает ни одной из сторон доверительного управления. Equite не управляет и не принимает участия в управлении средствами клиентов, инвестирующих в сервис ПАММ-счет.</p>
                </div>
            </div>
        </div>
    );
}

export default ServiceBlock;