import React from 'react';
import Icon1 from './assets/8.png';
import Icon2 from './assets/bg-mobile.png';
import './_styles.css';
import {Button} from "@material-ui/core";
import ItemStartMobile from "../start/item";

const ControllPamm = () => {
    return (
        <div className="control-pamm">
            <img src={Icon1} alt="" className="control-pamm_img hidden-mobile-1270"/>
            <img src={Icon2} alt="" className="hidden-desktop-1270 control-pamm_icon2"/>
            <div className="container">
                <div className="control-mobile">
                    <h2>Как стать управляющим <br/> трейдером ПАММ-счета?</h2>

                    <div className="hidden-desktop-1270" style={{flexDirection: 'column', alignItems: 'center', marginTop: 180}}>
                        <div className="start-block_mobile">
                            <ItemStartMobile number={1} text={'Зарегистрируйте Личный кабинет и пополните кошелек Equite удобным для вас способом! TRC20 USDT или Coin Payments'}/>
                            <ItemStartMobile number={2} text={'В личном кабинете Equite нажмите на кнопку + СОЗДАТЬ PAMM СЧЕТ Обратите внимание, на вашем кошельке должно быть не менее 1000$ для старта.'}/>
                            <ItemStartMobile number={3} text={'Задайте необходимые условия оферты при создании ПАММ-счета. Инвесторы будут знакомиться с офертой в процессе выбора счета.'}/>
                            <ItemStartMobile number={4} text={'Торгуйте при помощи API ключей на ПАММ-счете которым вы управляете. От результативности вашей торговли будет зависеть количество инвесторов присоединяющихся к вам.'}/>
                            <ItemStartMobile number={5} text={'нонсируйте возможность присоединения к управляемому вами ПАММ-счету в ваших социальных сетях и на вашем сайте при помощи виджета. Это привлечет новых инвесторов и увеличит объем торгов.'}/>
                        </div>
                        <Button variant="contained" color="primary" style={{marginTop: 30}}>СТАТЬ УПРАВЛЯЮЩИМ</Button>
                    </div>
                </div>

                <div className="control-title hidden-mobile-1270">
                    <h2>Как стать управляющим <br/> трейдером ПАММ-счета?</h2>
                    <Button variant="contained" color="primary">Стать Управляющим</Button>
                </div>

                <div className="control-texts hidden-mobile-1270">
                    <p className="control-texts-1">Зарегистрируйте Личный кабинет и пополните кошелек Equite удобным для вас способом! TRC20 USDT или Coin Payments</p>
                    <p className="control-texts-2">В личном кабинете Equite нажмите на кнопку + СОЗДАТЬ PAMM СЧЕТ Обратите внимание, на вашем кошельке должно быть не менее 1000$ для старта.</p>
                    <p className="control-texts-3">Задайте необходимые условия оферты при создании ПАММ-счета. Инвесторы будут знакомиться с офертой в процессе выбора счета.</p>
                    <p className="control-texts-4">Торгуйте при помощи API ключей на ПАММ-счете которым вы управляете. От результативности вашей торговли будет зависеть количество инвесторов присоединяющихся к вам.</p>
                    <p className="control-texts-5">Анонсируйте возможность присоединения к управляемому вами ПАММ-счету в ваших социальных сетях и на вашем сайте при помощи виджета. Это привлечет новых инвесторов и увеличит объем торгов.</p>
                </div>
            </div>
        </div>
    );
}

export default ControllPamm;