import React from 'react';
import './_styles.css';
import {Button, Grid} from "@material-ui/core";
import Bg from './assets/6.png'
import Bg1 from './assets/rectangle.svg'

const WhyBlock = () => {
    return (
        <div className="why-block">
            <img src={Bg} alt="" className="why-block_bg"/>
            <h2>Почему инвесторы выбирают ПАММ - <br/> сервис от Equite?</h2>
            <div className="container">
                <div className="why-block_content">
                    <div className="why-blocks">
                        <div className="why-blocks_item why-1">
                            <img src={Bg1} alt=""/>
                            <span>1</span>
                            <h3>Прозрачные принципы взаимодействия между инвестором и трейдером.</h3>
                            <p>Благодаря уникальному процессу сбора информации за счет трансляции сценариев по API ключам, Инвестор видит реальные показатели доходности трейдеров</p>
                        </div>
                        <div className="why-blocks_item why-2">
                            <img src={Bg1} alt=""/>
                            <span>2</span>
                            <h3>С нами просто начать инвестировать!</h3>
                            <p>Минимальный вход в ПАММ - счет составляет 500$. Платформа доступна для большого количества инвесторов, что повышает торговый объем и прибыль. Для крупных инвесторов есть программа лояльности и VIP статус.</p>
                        </div>
                        <div className="why-blocks_item why-3">
                            <img src={Bg1} alt=""/>
                            <span>3</span>
                            <h3>Уже сейчас инвесторы доверили нам более 70000$ в управление.</h3>
                            <p>Криптоиндустрия одно из самых перспективных для роста направлений на рынке торговли и инвестиций. Сервис Equite поможет вам соответствовать трендам меняющим мир.</p>
                        </div>
                    </div>
                </div>

                <div className="why-top">
                    <h3>
                        ТОП рейтинга наших ПАММ-счетов:
                    </h3>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <div className="why-top_item"></div>
                        </Grid>
                        <Grid item xs>
                            <div className="why-top_item"></div>
                        </Grid>
                    </Grid>

                    <Button variant="contained" color="primary">Выбрать ПАММ-счет</Button>
                </div>
            </div>
        </div>
    );
}

export default WhyBlock;