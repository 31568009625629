import './App.css';
import NavMenu from "./components/nav-menu";
import Header from "./components/header";
import BenefitsSection from "./components/benefits";
import SecureSection from "./components/secure";
import WhyBlock from "./components/why";
import StartBlock from "./components/start";
import ControllPamm from "./components/controll-pamm";
import FAQBlock from "./components/faq";
import ServiceBlock from "./components/service";
import SliderBlock from "./components/slider";

function App() {
  return (
    <div className="App">
      <NavMenu />
      <Header />
      <SliderBlock />
      <BenefitsSection />
      <SecureSection />
      <WhyBlock />
      <StartBlock />
      <ControllPamm/>
      <FAQBlock />
        <ServiceBlock />
    </div>
  );
}

export default App;
