import React from 'react';
import {Button} from "@material-ui/core";
import LogoIcon from './assets/img/logo.png';

import './_styles.css';

const NavMenu = () => {
    return (
        <div className="nav-menu">
            <div className="container">
                <div className="nav-menu_content">
                    <div className="nav-menu_container">
                        <div className="nav-menu_logo">
                            <img src={LogoIcon} alt=""/>
                        </div>

                        <div className="nav-menu_list">
                            <Button className="button">Home</Button>
                            <Button className="button">RATINGS</Button>
                            <Button className="button">BLOG</Button>
                            <Button className="button">PRO</Button>
                            <Button variant="outlined" color="secondary">TOURNAMENT</Button>
                            <Button className="button">PAMMS</Button>
                        </div>
                    </div>

                    <div className="nav-menu_actions">
                        <Button variant="outlined" color="secondary" style={{marginRight: 10}}>SIGN IN</Button>
                        <Button variant="contained" color="primary">TRY IT!</Button>
                        <Button color="primary">RU</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavMenu;