import React, {useState} from 'react';
import IconNumb from './assets/rectangle.svg';
import IconLineLeft from './assets/line-left.png';
import IconLineRight from './assets/line.png';
import IconArrowRight from './assets/arrow-right.png';
import IconArrowLeft from './assets/arrow-left.png';
import Slide from './assets/slide-1.png';
import Slide1Mobile from './assets/slide-1-mobile.png';
import Slide3Mobile from './assets/slide-3-mobile.png';
import Slide2Mobile from './assets/slide-2-mobile.png';
import Slide3 from './assets/slide-3.png';
import Slide2 from './assets/slide-2.png';
import './_styles.css';

const SliderBlock = () => {
    const [slide, setSlide] = useState(1);

    function slideText() {
        if (slide === 1) {
            return 'ПАММ - счет с балансом 5000$'
        }

        if (slide === 2) {
            return 'Прибыль составила +200% Баланс ПАММ счета теперь 15000$'
        }

        if (slide === 3) {
            return 'Распределение вознаграждения трейдера и расчет комиссии'
        }
    }

    function next() {
        if (slide === 1) {
           return  setSlide(2);
        }

        if (slide === 2) {
            return  setSlide(3);
        }

        if (slide === 3) {
            return  setSlide(1);
        }
    }

    function back() {
        if (slide === 1) {
            return  setSlide(3);
        }

        if (slide === 2) {
            return  setSlide(1);
        }

        if (slide === 3) {
            return  setSlide(2);
        }
    }

    return (
        <div className="slider-block">
            <div className="container">
                <div className="slider-block_title">
                    Как работает ПАММ-счет?
                </div>

                <div className="slider-block_slide">
                    <div className="slider-block_slide-number">
                        <img src={IconArrowLeft} alt="" className="icon-arrow-left" onClick={back}/>
                        <div className="slider-number_numb">
                            <img src={IconNumb} alt="" className="icon-num"/>
                            <p>{slide === 1 ? '1' : slide === 2 ? '2' : '3'}</p>
                            <img src={IconLineLeft} alt="" className="icon-line-left"/>
                            <img src={IconLineRight} alt="" className="icon-line-right"/>
                        </div>
                        <img src={IconArrowRight} alt="" className="icon-arrow-right" onClick={next}/>
                    </div>

                    <div className="slider-block_slide-item">
                        <h3>{slideText()}</h3>

                        <div className="slider-item-relative">
                            <img src={slide === 1 ? Slide : slide === 2 ? Slide2 : Slide3} alt=""/>
                        </div>

                        <div className="slide-dots">
                            <div className={`dots-item ${slide === 1 && 'active'}`} onClick={() => setSlide(1)}>
                                <div />
                            </div>

                            <div className={`dots-item ${slide === 2 && 'active'}`} onClick={() => setSlide(2)}>
                                <div />
                            </div>

                            <div className={`dots-item ${slide === 3 && 'active'}`} onClick={() => setSlide(3)}>
                                <div />
                            </div>
                        </div>
                    </div>

                    <div className="slider-block_slide-item hidden-desktop-1270" style={{flexDirection: 'column', textAlign: 'center'}}>
                        <h3>{slideText()}</h3>

                        <div className="slider-item-relative">
                            <img src={slide === 1 ? Slide1Mobile : slide === 2 ? Slide2Mobile : Slide3Mobile} alt="" style={{width: '360px'}}/>
                        </div>

                        <div className="slide-dots">
                            <div className={`dots-item ${slide === 1 && 'active'}`} onClick={() => setSlide(1)}>
                                <div />
                            </div>

                            <div className={`dots-item ${slide === 2 && 'active'}`} onClick={() => setSlide(2)}>
                                <div />
                            </div>

                            <div className={`dots-item ${slide === 3 && 'active'}`} onClick={() => setSlide(3)}>
                                <div />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SliderBlock;