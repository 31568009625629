import React from 'react';
import spotBg from './assets/bg.png';
import bgImage from './assets/bg-image.png';
import InfoSvg from './assets/info.svg';
import './_styles.css';
import {Button} from "@material-ui/core";

const Header = () => {
    return (
        <div className="header">
            <img src={spotBg} alt="" className="spotBg"/>
            <img src={bgImage} alt="" className="bgImage"/>

            <div className="container">
                <div className="header_content">
                    <h1>ПАММ <span>- счет от EQUITE</span></h1>
                    <p>ПАММ-счета - это профессиональный сервис для инвесторов, в котором можно зарабатывать не осуществляя торговые операции самостоятельно.</p>
                    <p>Инвестор вкладывает средства в ПАММ-счет от Equite находящийся под управлением трейдера, который управляет им и проводит торговые операции за фиксированное вознаграждение от прибыли.</p>

                    <div className="header_actions">
                        <Button variant="contained" color="primary">Инвестировать</Button>
                        <Button
                            variant="outlined" color="primary"
                            startIcon={<img src={InfoSvg} alt=''/>}
                        >Трейдеру</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;