import React from 'react';
import './_styles.css';

const BenefitsItem = (props) => {
    return (
        <div className="benefits-item">
            {props.children}
        </div>
    );
}

export default BenefitsItem;