import React from 'react';
import Bg from './assets/bg.png';
import TimeIcon from './assets/time.svg';
import BrainIcon from './assets/brain.svg';
import PazzleIcon from './assets/puzle.svg';
import GraphIcon from './assets/graphs.svg';
import GraphMoneyIcon from './assets/graph-money.svg';
import MoneyIcon from './assets/money.svg';
import './_styles.css';
import BenefitsItem from "./item";

const BenefitsSection = () => {
    return (
        <div className="benefits">
            <img src={Bg} alt="" className="benefits_bg"/>
            <div className="container">
                {/*<BenefitsItem/>*/}

                <h2>Выгоды ПАММ-счета для Инвестора</h2>

                <div className="benefits-content">
                    <div className="benefits-content_item benefits-timeBlock">
                        <BenefitsItem>
                            <img src={TimeIcon} alt="" style={{width: 60, height: 45}}/>
                        </BenefitsItem>

                        <p>Вы зарабатываете без самостоятельного осуществления торговых операций и экономите свое драгоценное время и нервы. Мы следим, чтобы ваши деньги работали.</p>
                    </div>

                    <div className="benefits-content_item benefits-brainBlock">
                        <BenefitsItem>
                            <img src={BrainIcon} alt="" style={{width: 56, height: 56, marginTop: 9}}/>
                        </BenefitsItem>

                        <p>Неограниченная доходность благодаря навыкам и знаниям лучших трейдеров. Только профессиональные практики с достоверными результатами.</p>
                    </div>

                    <div className="benefits-content_item benefits-pazzleBlock">
                        <BenefitsItem>
                            <img src={PazzleIcon} alt="" style={{width: 48, height: 48}}/>
                        </BenefitsItem>

                        <p>Диверсификация активов благодаря участию в нескольких ПАММ счетах. Сведет ваши риски к минимуму.</p>
                    </div>


                    <div className="benefits-content_item benefits-graphBlock">
                        <BenefitsItem>
                            <img src={GraphIcon} alt="" style={{width: 44, height: 44}}/>
                        </BenefitsItem>

                        <p>Высокая эффективность торговли! В отличие от копитрейдинга, торговля осуществляется напрямую, без дополнительного ПО, которое может привести к убыткам за счет ошибок системы.</p>
                    </div>

                    <div className="benefits-content_item benefits-moneyBlock">
                        <BenefitsItem>
                            <img src={MoneyIcon} alt="" style={{width: 48, height: 48}}/>
                        </BenefitsItem>

                        <p>Инвестируйте легко и безопасно! Минимальная сумма для входа в ПАММ счет начинается от 500$. Вы не передаете свои деньги трейдеру. Он не может их обналичить или снять с ПАММ счета.</p>
                    </div>

                    <div className="benefits-content_item benefits-graphMoneyBlock">
                        <BenefitsItem>
                            <img src={GraphMoneyIcon} alt="" style={{width: 48, height: 48}}/>
                        </BenefitsItem>
                        <p>Оптимизация рисков! Трейдер в ПАММ-счете, управляет большим количеством капиталов в рамках одного аккаунта - что снижает риски глубоких просадок, за счет концентрации.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BenefitsSection;