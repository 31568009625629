import React from 'react';
import CircleIcon from './assets/circle-money.svg';
import FindIcon from './assets/find.svg';
import MoneyIcon from './assets/money-icon.svg';
import StarIcon from './assets/star.svg';
import './_styles.css';

const SecureSection = () => {
    return (
        <div className="secure">
            <div className="container">
                <div className="secure-content">
                    <h2 className="titleBlock">Прозрачность и безопасность ваших активов</h2>

                    <div className="secure-content_items">
                        <div className="content_item">
                            <img src={FindIcon} alt=""/>
                            <div className="secure-line">
                                <div className="secure-line_child" />
                            </div>
                            <p>Наблюдайте за своей долей в ПАММ счете онлайн. Обновления происходят каждый час автоматически.</p>
                        </div>

                        <div className="content_item">
                            <img src={MoneyIcon} alt=""/>
                            <div className="secure-line">
                                <div className="secure-line_child" />
                            </div>
                            <p>Equite выступает гарантом целевого использования денег на счете. Сохранность денежных средств и доход клиентов являются основным приоритетом компании</p>
                        </div>

                        <div className="content_item">
                            <img src={CircleIcon} alt=""/>
                            <div className="secure-line">
                                <div className="secure-line_child" />
                            </div>
                            <p>Возможность вывода средств в любой момент. Так же доступно реинвестирование каждый период. Для активации "стратегии сложного процента"*</p>
                        </div>
                    </div>
                </div>

                <div className="secure-content_alert">
                    <img src={StarIcon} alt=""/>
                    <div className="secure-line">
                        <div className="secure-line_child" />
                    </div>
                    <p>"Стратегия сложного или волшебного процента"* - стратегия при которой прибыль не изымается со счета, а остается на нем, автоматически увеличивая вашу долю на эквивалентную сумму. Причисление процентов к сумме вклада, позволяет в дальнейшем осуществлять начисление процентов на проценты что дает более прогрессивный рост прибыли. Если вы ежегодно будете вкладывать в ПАММ-счет 500$ с доходностью, к примеру, 70% годовых, то через 5 лет, сумма вашего капитала составит порядка 16000$. Если бы прибыль изымалась, через 5 лет на счете было бы 2500$ а изъятая прибыль составила 1750$. Расчет приведен без уплаты комиссии.</p>
                </div>
            </div>
        </div>
    );
}

export default SecureSection;