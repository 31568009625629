import React from 'react';
import Icon from '../assets/rectangle.svg'
import './_styles.css';

const ItemStartMobile = (props) => {
    return (
        <div className='item-start-mobile'>
            <div className="item-start-mobile_img">
                <img src={Icon} alt=""/>
                <span>{props.number}</span>
            </div>

            <p>{props.text}</p>
        </div>
    );
}

export default ItemStartMobile;