import React from 'react';
import './_styles.css';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const FAQBlock = () => {
    return (
        <div className="faq">
            <div className="container">
                <div className="faq_content">
                    <h2>FAQ</h2>

                    <div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Как распределяются прибыли и убытки на счете и какую комиссию я заплачу?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography >Сколько по времени происходит ввод и вывод средств на кошелек и ПАММ-счет?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Почему ПАММ-счет для меня выгоднее чем прямое управление средствами или коп-трейдинг?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="p" className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Нужно ли мне разбираться в тонкостях рынка и криптовалютах чтобы не потерять свои инвестиции?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Что делать если рынок падает? Нужно ли срочно выводить активы со счета?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Сколько я могу заработать на ПАММ-счетах?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Как выбрать ПАММ-счета? На что обратить внимание при анализе управляющего?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Что такое РОЛЛОВЕР?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Что такое диверсификация портфеля и как это сделать?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Может ли трейдер - управляющий ПАММ-счетом присвоить мои деньги?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-accordion-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                    sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQBlock;